@keyframes slideFromTop {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideToTop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideToBottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.DrawerContent[data-side="top"][data-state="open"] {
  animation: slideFromTop 500ms cubic-bezier(0.32, 0.72, 0, 1);
}

.DrawerContent[data-side="top"][data-state="closed"] {
  animation: slideToTop 500ms cubic-bezier(0.32, 0.72, 0, 1);
}

.DrawerContent[data-side="bottom"][data-state="open"] {
  animation: slideFromBottom 500ms cubic-bezier(0.32, 0.72, 0, 1);
}

.DrawerContent[data-side="bottom"][data-state="closed"] {
  animation: slideToBottom 500ms cubic-bezier(0.32, 0.72, 0, 1);
}

.DrawerContent[data-side="right"][data-state="open"] {
  animation: slideFromRight 500ms cubic-bezier(0.32, 0.72, 0, 1);
}

.DrawerContent[data-side="right"][data-state="closed"] {
  animation: slideToRight 500ms cubic-bezier(0.32, 0.72, 0, 1);
}

.DrawerContent[data-side="left"][data-state="open"] {
  animation: slideFromLeft 500ms cubic-bezier(0.32, 0.72, 0, 1);
}

.DrawerContent[data-side="left"][data-state="closed"] {
  animation: slideToLeft 500ms cubic-bezier(0.32, 0.72, 0, 1);
}
